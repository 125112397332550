#root {
  background-color: white;
}

.main-entry-builder {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
}

.developer-view .main {
  display: flex;
  flex-flow: row wrap;
}

.developer-view .main .content-builder {
  flex: 1 0 1500px;
  height: 1200px;
}

.content-builder {
  flex: 1 0 1px;
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
}

.content-builder .spinner-wrapper {
  margin-right: 8px;
}

.content-builder
  .modalHeader-actions
  .spinner-wrapper
  svg.MuiCircularProgress-svg {
  color: white;
}

.content-builder-body {
  flex: 1 0 1px;
  display: flex;
  flex-flow: row nowrap;
  overflow-y: hidden;
}

.content-builder-body .spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-builder-preview {
  flex: 1 0 1px;
  overflow-y: auto;
  padding-top: 2px;
  padding-bottom: 100px;
}

.main-entry-builder-content-builder-container {
  border: dashed 1px black;
}

.variable-display {
  border: solid 1px black;
  border-radius: 1em;
  margin: 0.5em;
}

.variable-display p {
  margin: 0.5em;
}

.html-viewer {
  overflow: auto;
}

.object-viewer .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;
  font-size: 8px;
}

.object-viewer .content textarea {
  font-size: inherit;
}

.debug {
  overflow: scroll;
}

.css-panel textarea {
  width: 100%;
  height: 300px;
  overflow: scroll;
  margin: 1em 0;
}

.css-entry .error {
  font-size: 0.5em;
  color: red;
}

.model-migrator textarea {
  width: 100%;
  height: 300px;
}

.model-migrator pre {
  border: #333 solid 1px;
  padding: 2em 1em;
}

.model-migrator .error {
  color: red;
}

.new-content-creator {
  grid-column-start: 2;
  grid-column-end: 4;
}

.property-control {
  margin: 16px 0px;
}

/* Remove margin from first item because divider already has padding */
.property-control:first-child {
  margin-top: 0;
}
